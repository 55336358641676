import React from "react";

import {
  formatCpf,
  formatPhone,
  statusNameMap,
  getStatusColor,
} from "./../../utils/utils";
import Tag from "../Tag/Tag";
import * as S from "./styled";
import { ClientResponse } from "../../api-types";

type Props = {
  data: ClientResponse;
  dropdown?: boolean;
  blank?: boolean;
};

const ClientItem: React.FC<Props> = ({ data, dropdown, blank }) => {
  const target = blank ? { target: "_blank" } : {};

  const content = (
    <>
      <S.Status>
        <Tag block={true} color={getStatusColor(data)}>
          {data.is_owing ? "SPC" : statusNameMap[data.status]}
        </Tag>
      </S.Status>
      <S.Name>
        <span>{data.name.toLowerCase()}</span>
      </S.Name>
      <S.Doc>{formatCpf(data.cpf)}</S.Doc>
      <S.Phone>{formatPhone(data.whatsapp)}</S.Phone>
    </>
  );

  if (dropdown) {
    return (
      <S.ClientDropdown to={`/cliente/${data.id}`} {...target}>
        {content}
      </S.ClientDropdown>
    );
  }

  return (
    <S.Client to={`/cliente/${data.id}`} {...target}>
      {content}
    </S.Client>
  );
};

export default ClientItem;
