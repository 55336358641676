import React from "react";
import InputMask from "react-input-mask";
import { Input } from "antd";

type InputProps = {
  mask: string;
  placeholder: string;
  disabled?: boolean;
  onChange?: (event: React.SyntheticEvent) => void;
  value?: string;
};

const MaskInput: React.FC<InputProps> = ({
  disabled,
  onChange,
  value,
  mask,
  ...rest
}) => (
  <InputMask
    mask={mask}
    disabled={disabled}
    value={value}
    maskChar={null}
    onChange={onChange}
  >
    {(inputProps: any) => (
      <Input {...inputProps} {...rest} disabled={disabled} />
    )}
  </InputMask>
);

export default MaskInput;
