import { UserResponse } from "./../api-types";
import { Store } from "antd/lib/form/interface";
import { network_temp } from "./../utils/api";
import { LoginResponse, ClientResponse } from "../api-types";

export const api = {
  login(values: { username: string; password: string }) {
    return network_temp.post<LoginResponse>("/auth/login", values, {
      withCredentials: true,
    });
  },

  getToken() {
    return network_temp.post<LoginResponse>(
      "/auth/token",
      {},
      { withCredentials: true }
    );
  },

  logout() {
    return network_temp.post<ClientResponse[]>(
      `/auth/logout`,
      {},
      { withCredentials: true }
    );
  },

  getClient(id: string) {
    return network_temp.get<ClientResponse>(`/clients/${id}`);
  },

  getClients(searchTerm: string) {
    return network_temp.get<ClientResponse[]>(`/clients/?${searchTerm}`);
  },

  getPendingClients() {
    return network_temp.get<ClientResponse[]>(`/clients/pending`);
  },

  getDueClients() {
    return network_temp.get<ClientResponse[]>(`/clients/due`);
  },

  registerClient(id: number) {
    return network_temp.post<ClientResponse>(`/clients/register/${id}`);
  },

  addClientCode(id: number, value: { client_code: string }) {
    return network_temp.post<ClientResponse>(
      `/clients/client_code/${id}`,
      value
    );
  },

  editClient(id: string, values: Store) {
    return network_temp.patch<ClientResponse>(`/clients/${id}`, values);
  },

  createClient(values: Store) {
    return network_temp.post<ClientResponse>(`/clients`, values);
  },

  deleteClient(id: string) {
    return network_temp.delete<{ message: string }>(`/clients/${id}`);
  },

  checkCpf(cpf: string) {
    return network_temp.post<ClientResponse>(`/clients/match`, { cpf });
  },

  checkWpp(whatsapp: string) {
    return network_temp.post<ClientResponse[]>(`/clients/match_wpp`, {
      whatsapp,
    });
  },

  checkMother(mother: string) {
    return network_temp.post<ClientResponse[]>(`/clients/match_mother`, {
      mother,
    });
  },

  getUsers() {
    return network_temp.get<UserResponse[]>(`/users`);
  },

  editUser(id: number, values: Store) {
    return network_temp.patch<UserResponse>(`/users/${id}`, values);
  },

  createUser(values: Store) {
    return network_temp.post<UserResponse>(`/users`, values);
  },
};
