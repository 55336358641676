import React from "react";
import { WarningFilled } from "@ant-design/icons";

import theme from "../../theme";
import * as S from "./styled";
import { ClientResponse } from "../../api-types";
import ClientItem from "./ClientItem";

const STYLES = {
  color: theme.error_color,
  fontSize: 18,
};

const MODAL_STYLES = {
  margin: -24,
};

const ClientInputWarning: React.FC<{ clients: ClientResponse[] }> = ({
  clients,
}) => {
  const [modal, setModal] = React.useState(false);

  function toggleModal() {
    setModal((s) => !s);
  }

  return (
    <>
      <S.InputIcon onClick={toggleModal}>
        <WarningFilled style={STYLES} />
      </S.InputIcon>

      <S.WarningModal
        width={theme.width}
        title=""
        footer=""
        visible={modal}
        closable={false}
        onCancel={toggleModal}
      >
        <div style={MODAL_STYLES}>
          {clients.map((n) => (
            <ClientItem key={n.id} data={n} dropdown={true} blank={true} />
          ))}
        </div>
      </S.WarningModal>
    </>
  );
};

export default ClientInputWarning;
