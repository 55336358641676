import styled from "styled-components";

import theme from "./../../theme/index";

export const Tag = styled.div<{ color?: string; block?: boolean }>`
  width: ${({ block }) => (block ? "100%" : "auto")};
  height: 22px;
  align-items: center;
  background: ${({ color }) => (color ? color : theme.background)};
  border-radius: 2px;
  color: #ffffff;
  display: inline-flex;
  font-size: 12px;
  justify-content: center;
  text-transform: uppercase;
  padding: 0 8px;
`;
