import React from "react";
import { useParams } from "react-router-dom";

import ClientCard from "../components/Client/ClientCard";
import ClientEdit from "../components/Client/ClientEdit";
import Header from "../components/Header/Header";
import PageContainer from "../components/Main/PageContainer";
import ClientCardLoading from "../components/Client/ClientCardLoading";
import { useOvermind } from "../overmind";
import { ParamType } from "../api-types";

function Client() {
  const { id } = useParams<ParamType>();
  const { actions, state } = useOvermind();

  React.useEffect(() => {
    actions.getCurrentClient(id);

    return () => actions.clearCurrentClient();
  }, [actions, id]);

  function content() {
    if (state.loadingCurrentClient) {
      return <ClientCardLoading />;
    }

    if (!state.currentClient) {
      return null;
    }

    if (state.user.role === "employee") {
      return <ClientCard key={id} data={state.currentClient} />;
    }

    return <ClientEdit key={id} data={state.currentClient} />;
  }

  return (
    <>
      <Header />

      <PageContainer>{content()}</PageContainer>
    </>
  );
}

export default Client;
