import React from "react";

import * as S from "./styled";

export const FieldItem: React.FC<{ label: string }> = ({ label, children }) => {
  return (
    <S.FieldRow>
      <div>{label}</div>
      <div>{children}</div>
    </S.FieldRow>
  );
};

const FieldsList: React.FC = ({ children }) => {
  return <S.FieldsListContainer>{children}</S.FieldsListContainer>;
};

export default FieldsList;
