import styled from "styled-components";
import { Link } from "react-router-dom";

import theme from "../../theme";
import { Modal } from "antd";

const Center = styled.div`
  display: flex;
  align-items: center;
`;

export const Client = styled(Link)`
  width: 100%;
  min-height: 50px;
  background: #ffffff;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  display: flex;
  margin-bottom: 10px;
  padding: 10px 15px;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;

  &:hover {
    color: rgba(0, 0, 0, 0.65);
    transform: scale(0.99);
    opacity: 0.8;
  }
`;

export const ClientDropdown = styled(Link)`
  width: 100%;
  min-height: 50px;
  background: #ffffff;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.65);
  display: flex;
  padding: 10px 15px;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out,
    background 0.25s ease-in-out;

  &:not(:last-child) {
    border-bottom: 1px solid ${theme.border_color};
  }

  &:hover {
    background: #f5f5f5;
    color: rgba(0, 0, 0, 0.65);
  }
`;

export const Status = styled(Center)`
  width: 95px;
  flex-shrink: 0;
  margin-right: 10px;
  text-transform: uppercase;

  & > span:hover {
    opacity: 1;
  }
`;

export const Name = styled(Center)`
  display: flex;
  align-items: center;
  font-weight: bold;
  flex: 1;
  overflow: hidden;
  padding-right: 30px;
  text-transform: capitalize;

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Doc = styled(Center)`
  width: 120px;
  flex-shrink: 0;
  font-variant: common-ligatures tabular-nums slashed-zero;
  justify-content: flex-end;
  margin-right: 30px;
`;

export const Phone = styled(Center)`
  width: 120px;
  flex-shrink: 0;
  font-variant: common-ligatures tabular-nums slashed-zero;
  justify-content: flex-end;
`;

export const CardTitle = styled.h2`
  font-size: 30px;
  font-weight: bold;
  line-height: 1;
  margin: 8px 0;
  text-transform: capitalize;

  &:empty:before {
    content: attr(placeholder);
    color: #cbcbcb;
    pointer-events: none;
    text-transform: none;
  }

  &:focus {
    outline: none;
  }
`;

export const CardCpf = styled.p`
  font-size: 18px;
  line-height: 1;
  margin: 0;
  text-transform: capitalize;

  &:focus {
    outline: none;
  }
`;

export const CardHeader = styled.div<{ color: string }>`
  background: ${({ color }) => color};
  margin: -15px -15px 30px -15px;
  padding: 15px;
`;

export const FieldsListContainer = styled.div``;

export const FieldRow = styled.div`
  border-bottom: 1px solid #dbdbdb;
  display: flex;
  margin: 0 -15px;
  padding: 10px 0;

  &:first-child {
    margin-top: -15px;
  }

  &:last-child {
    border-bottom: none;
    margin-bottom: -15px;
  }

  & > div {
    align-items: center;
    display: flex;
    padding: 0 15px;
    white-space: pre-wrap;

    input {
      width: 100%;
      padding-right: 30px;
    }

    &:first-child {
      width: 170px;
      font-weight: bold;
      flex-shrink: 0;
    }

    &:last-child {
      width: 100%;
      position: relative;
    }
  }
`;

export const ClientFooter = styled.div`
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: -15px;
  padding: 25px 0;

  .confirm {
    background: ${theme.success_color};
    border-color: ${theme.success_color};

    &:hover {
      background: #84cba3;
      border-color: #84cba3;
    }
  }

  & > * {
    margin-left: 10px;
  }
`;

export const SelectStatusContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const SelectStatusCircle = styled.div<{ color: string }>`
  width: 14px;
  height: 14px;
  background: ${({ color }) => color};
  border-radius: 50px;
  margin-right: 5px;
`;

export const InputIcon = styled.div`
  cursor: pointer;
  display: inline-flex;
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translateY(-50%);
`;

export const PopoverClient = styled(Link)`
  width: 200px;
  color: rgba(0, 0, 0, 0.65);
  display: block;

  .name {
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    white-space: nowrap;
  }

  &:hover {
    color: rgba(0, 0, 0, 0.65);
  }
`;

export const WarningModal = styled(Modal)`
  .ant-modal-content {
    overflow: hidden;
  }
`;
