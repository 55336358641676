import React from "react";
import {
  LogoutOutlined,
  TeamOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { Dropdown, Menu } from "antd";

import * as S from "./styled";
import { ClientResponse } from "../../api-types";
import { formatCpf } from "../../utils/utils";
import SearchDropdown from "../SearchDropdown/SearchDropdown";
import { useOvermind } from "../../overmind";

type Props = {
  onChange?: (Event: React.ChangeEvent<HTMLInputElement>) => void | undefined;
  onBlur?: (Event: React.FocusEvent<HTMLInputElement>) => void | undefined;
};

const placeholder = "Busque clientes por Nome, CPF ou Whatsapp";

function clientDropdownList(
  clients: ClientResponse[],
  type: "pending" | "due"
) {
  return (
    <Menu style={{ width: 250, maxHeight: 500, overflowY: "auto" }}>
      {clients.map((n) => (
        <Menu.Item key={`${type}-${n.id}`}>
          <Link to={`/cliente/${n.id}`}>
            <S.DropdownClient>
              <S.DropdownClientName>
                {n.name.toLowerCase()}
              </S.DropdownClientName>
              <S.DropdownClientCpf>{formatCpf(n.cpf)}</S.DropdownClientCpf>
            </S.DropdownClient>
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  );
}

const Header: React.FC<Props> = ({ onChange, onBlur }) => {
  const { state, actions } = useOvermind();
  const location = useLocation();

  React.useEffect(() => {
    actions.getPendingClients();
    actions.getDueClients();
  }, [actions]);

  return (
    <>
      <S.Header>
        <S.Menu>
          <S.Left>
            {state.user.role && state.user.role !== "employee" && (
              <Link to="/novo" className="primary">
                Novo Cliente
              </Link>
            )}
            {state.pending && state.pending.length > 0 && (
              <Dropdown
                placement="bottomCenter"
                overlay={clientDropdownList(state.pending, "pending")}
                trigger={["click"]}
              >
                <button className="status-btn">
                  <S.Quantity>{state.pending.length}</S.Quantity> Pendente
                </button>
              </Dropdown>
            )}
            {state.due && state.due.length > 0 && (
              <Dropdown
                placement="bottomCenter"
                overlay={clientDropdownList(state.due, "due")}
                trigger={["click"]}
              >
                <button className="status-btn">
                  <S.Quantity>{state.due.length}</S.Quantity> Vencendo
                </button>
              </Dropdown>
            )}
          </S.Left>
          <S.Right>
            {state.user.role && state.user.role === "admin" && (
              <Link to="/usuarios">
                <TeamOutlined /> Usuários
              </Link>
            )}
            <button onClick={actions.logout}>
              <LogoutOutlined /> Logout
            </button>
          </S.Right>
        </S.Menu>
      </S.Header>

      <S.Search>
        {location.pathname === "/" ? (
          <S.SearchContainer>
            <SearchOutlined />
            <S.SearchInput
              onChange={onChange}
              onBlur={onBlur}
              placeholder={placeholder}
            />
          </S.SearchContainer>
        ) : (
          <SearchDropdown>
            <S.SearchContainer>
              <SearchOutlined />
              <S.SearchInput placeholder={placeholder} />
            </S.SearchContainer>
          </SearchDropdown>
        )}
      </S.Search>
    </>
  );
};

export default Header;
