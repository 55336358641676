import React from "react";
import { Switch, Alert, Input } from "antd";
import differenceInDays from "date-fns/differenceInDays";

import Tag from "./../Tag/Tag";
import * as G from "./../global-styled";
import * as S from "./styled";
import {
  formatCpf,
  formatPhone,
  statusNameMap,
  clientFieldsMap,
  getStatusColor,
} from "../../utils/utils";
import FieldsList, { FieldItem } from "./FieldsList";
import { ClientResponse } from "../../api-types";
import { useOvermind } from "../../overmind";

const ARBITRARY_ALERT_STYLE = {
  margin: "-10px 0 20px 0",
};

const ClientCard: React.FC<{ data: ClientResponse }> = ({ data }) => {
  const { actions } = useOvermind();
  const { status, name, cpf, client_code, ...rest } = data;

  async function onChangeRegistered(value: boolean) {
    actions.registerClient(data.id);
  }

  async function onChangeClientCode(event: any) {
    const { value } = event.target;
    if (data.client_code !== value.trim()) {
      actions.addClientCode({ id: data.id, client_code: value });
    }
  }

  function showLateDays() {
    if (data && data.approval_date && !data.is_registered) {
      const diff = differenceInDays(new Date(), new Date(data.approval_date));
      if (diff > 15) {
        const days = diff - 15;
        const string = days > 1 ? "dias" : "dia";
        return (
          <Alert
            style={ARBITRARY_ALERT_STYLE}
            message={`Pré-cadastro vencido há ${days} ${string}.`}
            type="error"
            showIcon
          />
        );
      }
    }
    return null;
  }

  return (
    <G.WhiteCard>
      <S.CardHeader color="#f4f4f4">
        <Tag color={getStatusColor(data)}>
          {data.is_owing ? "SPC" : statusNameMap[status]}
        </Tag>
        <S.CardTitle>{name.toLowerCase()}</S.CardTitle>
        <S.CardCpf>{formatCpf(cpf)}</S.CardCpf>
      </S.CardHeader>

      {showLateDays()}

      <FieldsList>
        {status === "approved" && (
          <FieldItem label="Cadastro Completo">
            <Switch
              size="small"
              onChange={onChangeRegistered}
              checked={data.is_registered}
            />
          </FieldItem>
        )}

        {data.is_registered && (
          <FieldItem label="Código">
            <Input defaultValue={client_code} onBlur={onChangeClientCode} />
          </FieldItem>
        )}

        {Object.entries(rest).map(([key, value]) => {
          if (key in clientFieldsMap && value) {
            if (key === "whatsapp") {
              value = formatPhone(value);
            }
            return (
              <FieldItem
                key={key}
                label={
                  clientFieldsMap[key as keyof typeof clientFieldsMap] as string
                }
              >
                {value}
              </FieldItem>
            );
          }

          return null;
        })}
      </FieldsList>
    </G.WhiteCard>
  );
};

export default ClientCard;
