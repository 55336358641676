import styled from "styled-components";

export const WhiteCard = styled.div`
  width: calc(100% - 20%);
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 6.7px 1.1px -21px rgba(0, 0, 0, 0.039),
    0 11px 3.6px -21px rgba(0, 0, 0, 0.057),
    0 19px 16px -21px rgba(0, 0, 0, 0.1);
  margin: 0px auto 40px auto;
  overflow: hidden;
  padding: 15px;
`;

export const Login = styled.div`
  width: 100vw;
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: center;

  .ant-alert {
    width: 100%;
    position: absolute;
    top: -50px;
  }

  form {
    width: 300px;
    position: relative;

    .username {
      margin-bottom: 8px;
    }

    .password {
      margin-bottom: 12px;
    }

    button {
      text-transform: uppercase;
    }
  }
`;
