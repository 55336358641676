import styled from "styled-components";

import theme from "./../../theme";

export const Header = styled.div`
  width: 100%;
  background: ${theme.darker_main_color};
  padding: 15px 10px;
  transition: height 0.25s ease-in-out;
`;

export const Menu = styled.div`
  width: ${theme.width}px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
`;

export const Search = styled.div`
  width: 100%;
  align-items: center;
  background: #ffffff;
  display: flex;
  justify-content: center;
`;

export const SearchContainer = styled.div`
  width: ${theme.width}px;
  margin: 0px auto;
  position: relative;

  .anticon {
    opacity: 0.4;
    font-size: 22px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 60px;
  background: #ffffff;
  border: none;
  font-size: 16px;
  padding: 0 10px 0 30px;

  &:focus {
    outline: none;
  }
`;

export const Left = styled.div`
  display: flex;

  button {
    background: none;
    cursor: pointer;

    &:focus {
      outline: none;
      box-shadow: none;
      border-color: #ffffff;
      background: #ffffff;
      color: ${theme.darker_main_color};
    }

    &.status-btn:focus {
      border-color: #ffffff;
      background: #ffffff;
      color: ${theme.darker_main_color};

      span {
        background: ${theme.darker_main_color};
        color: #ffffff;
      }
    }
  }

  a,
  button {
    height: 30px;
    align-items: center;
    border: 1px solid ${theme.main_color};
    border-radius: 4px;
    color: ${theme.main_color};
    display: flex;
    justify-content: center;
    padding: 0 10px;
    transition: border 0.25s ease-in-out, color 0.25s ease-in-out,
      background 0.25s ease-in-out;

    &:not(:last-child) {
      margin-right: 10px;
    }

    &:hover {
      border: 1px solid ${theme.background};
      color: ${theme.background};

      span {
        background: ${theme.background};
      }
    }

    &.primary {
      background: ${theme.main_color};
      color: ${theme.darker_main_color};
      font-weight: bold;

      &:hover {
        background: ${theme.background};
      }
    }
  }
`;

export const Right = styled.div`
  button {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;

    &:focus {
      border: none;
      outline: none;
    }
  }

  a,
  button {
    color: ${theme.main_color};

    &:hover {
      color: ${theme.background};
    }

    &:not(:last-child) {
      margin-right: 20px;
    }
  }
`;

export const Quantity = styled.span`
  width: 15px;
  height: 15px;
  align-items: center;
  background: ${theme.main_color};
  border-radius: 20px;
  color: ${theme.darker_main_color};
  display: inline-flex;
  font-size: 10px;
  font-weight: bold;
  justify-content: center;
  margin-right: 5px;
  transition: background 0.25s ease-in-out, color 0.25s ease-in-out;
`;

export const DropdownClient = styled.div``;

export const DropdownClientName = styled.div`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  white-space: nowrap;
`;

export const DropdownClientCpf = styled.div`
  font-variant: common-ligatures tabular-nums slashed-zero;
  flex-shrink: 0;
`;
