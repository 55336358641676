import styled from "styled-components";
import theme from "../../theme";

export const Item = styled.div`
  border-bottom: 1px solid ${theme.border_color};
  cursor: pointer;
  display: flex;
  margin: 0 -15px;
  justify-content: space-between;
  padding: 15px;
  transition: background 0.25s ease-in-out;

  & > div {
    align-items: center;
    display: flex;
  }

  &:hover {
    background: #f5f5f5;
  }

  &:last-child {
    margin-bottom: -15px;
    border-bottom: none;
  }
`;

export const Tag = styled.div`
  width: 80px;
  flex-shrink: 0;
  margin-right: 10px;
  text-transform: uppercase;

  span {
    width: 100%;
    text-align: center;
  }
`;

export const Name = styled.div`
  font-weight: bold;
`;

export const Status = styled.div`
  span {
    margin-right: 0;
    text-transform: uppercase;
  }
`;

export const Button = styled.button`
  width: 800px;
  height: 54px;
  align-items: center;
  background: none;
  border: none;
  border-bottom: 1px solid ${theme.border_color};
  color: ${theme.darker_main_color};
  cursor: pointer;
  display: flex;
  margin: -15px -15px 0 -15px;
  justify-content: center;
  padding: 0;
  transition: background 0.25s ease-in-out;

  span {
    margin-right: 5px;
  }

  &:hover {
    background: #f5f5f5;
  }

  &:focus {
    outline: none;
  }
`;
