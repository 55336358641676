import React from "react";
import { Modal, Row, Col, Form, Input, Select, Switch } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import Header from "../components/Header/Header";
import PageContainer from "../components/Main/PageContainer";
import * as G from "./../components/global-styled";
import UserItem from "../components/UserItem/UserItem";
import { Store } from "antd/lib/form/interface";
import UserNew from "../components/UserItem/UserNew";
import { useOvermind } from "../overmind";

const baseProperties = {
  first_name: null,
  last_name: null,
  email: null,
  username: null,
  is_active: true,
  is_superuser: false,
  password: null,
  role: "employee",
};

const MANDATORY_MESSAGE = "Campo obrigatório!";

function Users() {
  const { state, actions } = useOvermind();
  const [form] = Form.useForm();
  const [modal, setModal] = React.useState(false);
  const [selectedId, setSelectedId] = React.useState<null | number>(null);

  React.useEffect(() => {
    actions.getUsers();

    return () => actions.clearUsers();
  }, [actions]);

  function mandatory() {
    if (!selectedId) {
      return [{ required: true, message: MANDATORY_MESSAGE }];
    }

    return;
  }

  function onClickUser(id: number) {
    const selected = state.users.find((d) => d.id === id);
    selected && form.setFieldsValue(selected);
    setModal(true);
    setSelectedId(id);
  }

  function onOpenNew() {
    form.setFieldsValue(baseProperties);
    setModal(true);
  }

  function onCancel() {
    setModal(false);
    setSelectedId(null);
    form.setFieldsValue(baseProperties);
  }

  async function onEdit(values: Store) {
    if (selectedId) {
      actions.editUser({ id: selectedId, values });
    }
  }

  async function onCreate(values: Store) {
    actions.createUser({ user: values, callback: onCancel });
  }

  return (
    <>
      <Header />

      {state.users ? (
        <>
          <PageContainer>
            <G.WhiteCard>
              <UserNew onClick={onOpenNew}>
                <PlusCircleOutlined /> Novo Usuário
              </UserNew>
              {state.users.length > 0 ? (
                state.users.map((u) => (
                  <UserItem onClick={onClickUser} key={u.id} data={u} />
                ))
              ) : (
                <p></p>
              )}
            </G.WhiteCard>
          </PageContainer>

          <Modal
            visible={modal}
            onCancel={onCancel}
            destroyOnClose={true}
            okText={selectedId ? "Editar Usuário" : "Novo Usuário"}
            cancelText="Cancelar"
            onOk={() => form.submit()}
          >
            <Form
              form={form}
              layout="vertical"
              onFinish={selectedId ? onEdit : onCreate}
            >
              <Row gutter={20}>
                <Col span={24}>
                  <Form.Item name="is_active" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item
                    required={false}
                    rules={mandatory()}
                    name="first_name"
                    label="Nome"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    required={false}
                    rules={mandatory()}
                    name="last_name"
                    label="Sobrenome"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item
                    required={false}
                    rules={mandatory()}
                    name="email"
                    label="E-mail"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    required={false}
                    rules={mandatory()}
                    name="role"
                    label="Role"
                  >
                    <Select>
                      <Select.Option key="admin" value="admin">
                        Admin
                      </Select.Option>
                      <Select.Option key="manager" value="manager">
                        Gerente
                      </Select.Option>
                      <Select.Option key="employee" value="employee">
                        Colaborador
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item
                    required={false}
                    rules={mandatory()}
                    name="username"
                    label="Usuário"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    required={false}
                    rules={mandatory()}
                    name="password"
                    label="Senha"
                  >
                    <Input type="password" />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        </>
      ) : null}
    </>
  );
}

export default Users;
