import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Form, Input, Button, Select, Switch, Popconfirm, Alert } from "antd";
import differenceInDays from "date-fns/differenceInDays";

import * as G from "./../global-styled";
import * as S from "./styled";

import { ClientResponse, ParamType } from "../../api-types";
import theme from "../../theme";
import { statusNameMap, clientFieldsMap, onlyNumbers } from "../../utils/utils";
import FieldsList, { FieldItem } from "./FieldsList";
import MaskInput from "../MaskInput";
import { Store } from "antd/lib/form/interface";
import ClientInputError from "./ClientInputError";
import ClientInputWarning from "./ClientInputWarning";
import { useOvermind } from "../../overmind";

type CleanEditProps = {
  newForm?: boolean;
  data?: ClientResponse;
};

const ARBITRARY_SELECT_STYLE = {
  width: 150,
};

const ARBITRARY_ALERT_STYLE = {
  margin: "-10px 0 20px 0",
};

const ClientEdit: React.FC<CleanEditProps> = ({ newForm, data }) => {
  const { id } = useParams<ParamType>();
  const history = useHistory();
  const { effects, actions } = useOvermind();
  const [form] = Form.useForm();
  const [matchingCpf, setMatchingCpf] = React.useState<null | ClientResponse>(
    null
  );
  const [matchingWpp, setMatchingWpp] = React.useState<null | ClientResponse[]>(
    null
  );
  const [matchingMother, setMatchingMother] = React.useState<
    null | ClientResponse[]
  >(null);
  const [currentStatus, setCurrentStatus] = React.useState<
    null | keyof typeof theme.status
  >(data?.status ?? null);

  const editButtonDisabled = matchingCpf ? true : false;

  async function onFinish(values: Store) {
    if (newForm) {
      const id = await actions.postClient(values);
      if (id) {
        history.push(`/cliente/${id}`);
      }
    } else {
      const res = await actions.editClient({ id, values });
      if (res) {
        form.setFieldsValue({
          is_registered: res.is_registered,
          is_owing: res.is_owing,
          client_code: res.client_code,
        });
      }
    }
  }

  async function deleteClient() {
    const res = actions.removeClient(id);
    if (res) {
      history.push("/");
    }
  }

  function onNameChange(e: any) {
    form.setFieldsValue({ name: e.currentTarget.innerText });
  }

  function onChangeStatus(value: keyof typeof theme.status) {
    setCurrentStatus(value);
  }

  async function onChangeCpf(e: any) {
    const { value } = e.target;

    if (onlyNumbers(value).length >= 11) {
      const res = await effects.api.checkCpf(onlyNumbers(value));
      if (res.data.id && res.data.id !== Number(id)) {
        return setMatchingCpf(res.data);
      }
    }
    setMatchingCpf(null);
  }

  async function onChangeWpp(e: any) {
    const { value } = e.target;

    if (onlyNumbers(value).length >= 9) {
      const res = await effects.api.checkWpp(onlyNumbers(value));
      if (res.data) {
        const validResults = res.data.filter((n) => n.id !== Number(id));
        if (validResults.length > 0) {
          return setMatchingWpp(validResults);
        }
      }
    }
    setMatchingWpp(null);
  }

  async function onBlurMother() {
    const mother = form.getFieldValue("mother");
    if (mother) {
      const res = await effects.api.checkMother(mother);
      if (res.data) {
        const validResults = res.data.filter((n) => n.id !== Number(id));
        if (validResults.length > 0) {
          return setMatchingMother(validResults);
        }
      }
    }
    setMatchingMother(null);
  }

  function onFocusMother() {
    setMatchingMother(null);
  }

  function showLateDays() {
    if (data && data.approval_date && !data.is_registered) {
      const diff = differenceInDays(new Date(), new Date(data.approval_date));
      if (diff > 15) {
        const days = diff - 15;
        const string = days > 1 ? "dias" : "dia";
        return (
          <Alert
            style={ARBITRARY_ALERT_STYLE}
            message={`Pré-cadastro vencido há ${days} ${string}.`}
            type="error"
            showIcon
          />
        );
      }
    }
    return null;
  }

  return (
    <G.WhiteCard>
      <Form form={form} onFinish={onFinish}>
        <S.CardHeader color="#f4f4f4">
          <Form.Item
            name="status"
            initialValue={data?.status ?? "pending"}
            noStyle
          >
            <Select style={ARBITRARY_SELECT_STYLE} onChange={onChangeStatus}>
              {Object.entries(statusNameMap).map(([key, label]) => (
                <Select.Option key={key} value={key}>
                  <S.SelectStatusContainer>
                    <S.SelectStatusCircle
                      color={theme.status[key as keyof typeof theme.status]}
                    />
                    {label}
                  </S.SelectStatusContainer>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="name"
            initialValue={data?.name.toLowerCase()}
            noStyle
          >
            <S.CardTitle
              placeholder="Digite o nome do cliente"
              contentEditable={true}
              suppressContentEditableWarning={true}
              onInput={onNameChange}
            >
              {data?.name.toLowerCase()}
            </S.CardTitle>
          </Form.Item>
        </S.CardHeader>

        {showLateDays()}

        <FieldsList>
          {currentStatus === "approved" && (
            <FieldItem label="SPC">
              <Form.Item
                valuePropName={"checked"}
                initialValue={data?.is_owing}
                name="is_owing"
                noStyle={true}
                shouldUpdate={true}
              >
                <Switch size="small" />
              </Form.Item>
            </FieldItem>
          )}
          {currentStatus === "approved" && (
            <FieldItem label="Cadastro Completo">
              <Form.Item
                valuePropName={"checked"}
                initialValue={data?.is_registered}
                name="is_registered"
                noStyle={true}
                shouldUpdate={true}
              >
                <Switch size="small" />
              </Form.Item>
            </FieldItem>
          )}
          <Form.Item noStyle={true} shouldUpdate={true}>
            {() =>
              form.getFieldValue("is_registered") ? (
                <FieldItem label="Código">
                  <Form.Item
                    initialValue={data?.client_code}
                    name="client_code"
                    noStyle={true}
                    shouldUpdate={true}
                  >
                    <Input />
                  </Form.Item>
                </FieldItem>
              ) : null
            }
          </Form.Item>
          {Object.entries(clientFieldsMap).map(([key, value]) => {
            let component = <Input />;
            let error;

            if (key === "cpf") {
              component = (
                <MaskInput
                  mask="999.999.999-99"
                  placeholder={""}
                  onChange={onChangeCpf}
                />
              );

              if (matchingCpf) {
                error = <ClientInputError client={matchingCpf} />;
              }
            }

            if (key === "whatsapp") {
              component = (
                <MaskInput
                  mask="99999-9999"
                  placeholder={""}
                  onChange={onChangeWpp}
                />
              );

              if (matchingWpp) {
                error = <ClientInputWarning clients={matchingWpp} />;
              }
            }

            if (key === "mother") {
              component = React.cloneElement(component, {
                onBlur: onBlurMother,
                onFocus: onFocusMother,
              });

              if (matchingMother) {
                error = <ClientInputWarning clients={matchingMother} />;
              }
            }

            if (key === "information") {
              component = <Input.TextArea rows={4} />;
            }

            return (
              <FieldItem key={key} label={value as string}>
                <Form.Item
                  initialValue={data?.[key as keyof ClientResponse] as string}
                  name={key}
                  noStyle={true}
                >
                  {component}
                </Form.Item>

                {error}
              </FieldItem>
            );
          })}
        </FieldsList>

        {newForm ? (
          <S.ClientFooter>
            <Button type="primary" htmlType="submit">
              Adicionar Novo Cliente
            </Button>
          </S.ClientFooter>
        ) : (
          <S.ClientFooter>
            <Popconfirm
              title="Confirmar deleção do cliente?"
              onConfirm={deleteClient}
              okText="Sim"
              okButtonProps={{ danger: true }}
              cancelText="Não"
            >
              <Button type="primary" danger={true}>
                Excluir
              </Button>
            </Popconfirm>
            <Button
              type="primary"
              htmlType="submit"
              disabled={editButtonDisabled}
            >
              Salvar Edições
            </Button>
          </S.ClientFooter>
        )}
      </Form>
    </G.WhiteCard>
  );
};

export default ClientEdit;
