import React from "react";

import * as G from "./../global-styled";
import * as S from "./styled";

const CARD_STYLE = {
  height: 720,
};

const HEADER_STYLE = {
  height: 112,
};

function ClientCardLoading() {
  return (
    <G.WhiteCard style={CARD_STYLE}>
      <S.CardHeader style={HEADER_STYLE} color="#f4f4f4"></S.CardHeader>
    </G.WhiteCard>
  );
}

export default ClientCardLoading;
