import React from "react";
import ReactDOM from "react-dom";
import {
  Router,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import { createOvermind } from "overmind";
import { createBrowserHistory } from "history";
import "antd/dist/antd.less";

import Client from "./pages/Client";
import Users from "./pages/Users";
import Home from "./pages/Home";
import Login from "./pages/Login";
import ClientNew from "./pages/ClientNew";
import { config, useOvermind } from "./overmind";
import { Provider } from "overmind-react";

const overmind = createOvermind(config, {
  devtools: true,
});
export const theHistory = createBrowserHistory();

function Routes() {
  const history = useHistory();
  const { pathname } = useLocation();
  const { state, reaction } = useOvermind();

  React.useEffect(() => {
    return reaction(
      (state) => state.user.id,
      (id) => {
        if (!id) {
          history.push("/login");
        } else {
          let toGo = pathname;
          if (pathname === "/login") {
            toGo = "/";
          }
          history.push(toGo ?? "/");
        }
      }
    );
  }, [history, reaction, pathname]);

  React.useEffect(() => {
    if (!state.user.id && !state.initialLoading) {
      history.push("/login");
    }
  }, [history, state.user.id, state.initialLoading]);

  if (state.initialLoading) {
    return null;
  }

  return (
    <Switch>
      <Route path="/cliente/:id" component={Client} />
      <Route path="/novo" component={ClientNew} />
      <Route path="/usuarios" component={Users} />
      <Route path="/login" component={Login} />
      <Route path="/" component={Home} />
    </Switch>
  );
}

function App() {
  return (
    <Router history={theHistory}>
      <Route component={Routes} />
    </Router>
  );
}

ReactDOM.render(
  <Provider value={overmind}>
    <App />
  </Provider>,
  document.getElementById("root")
);
