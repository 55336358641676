import React from "react";
import { Dropdown } from "antd";

import * as S from "./styled";
import { useQueryClients } from "../../utils/hooks";
import ClientItem from "../Client/ClientItem";
import { useOvermind } from "../../overmind";

const SearchDropdown: React.FC<any> = ({ children }) => {
  const { state } = useOvermind();
  const search = useQueryClients();

  const content = (
    <S.Container>
      {state.clients.length > 0 &&
        state.clients.map((n) => (
          <ClientItem key={n.id} data={n} dropdown={true} />
        ))}
    </S.Container>
  );
  return (
    <Dropdown placement="bottomCenter" overlay={content} trigger={["click"]}>
      {React.cloneElement(children, { onChange: search })}
    </Dropdown>
  );
};

export default SearchDropdown;
