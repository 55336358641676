module.exports = {
  width: 1000,
  background: "#EBECED",
  main_color: "#b7b1ff",
  darker_main_color: "#38319d",
  border_color: "#dbdbdb",
  success_color: "#56b984",
  error_color: "#f6765e",
  warning_color: "#eecd57",
  owing: "#ff905b",
  status: {
    undefined: "#a7a7a7",
    inperson: "#5f9fd0",
    pending: "#eecd57",
    disapproved: "#f6765e",
    approved: "#56b984",
    client: "#38319d",
  },
};
