import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1.1px 1px -24px rgba(0, 0, 0, 0.065),
    0 2.6px 2.4px -24px rgba(0, 0, 0, 0.093),
    0 4.7px 4.3px -24px rgba(0, 0, 0, 0.115),
    0 7.9px 7.1px -24px rgba(0, 0, 0, 0.135),
    0 13px 11.6px -24px rgba(0, 0, 0, 0.157),
    0 22.7px 20.3px -24px rgba(0, 0, 0, 0.185),
    0 49px 44px -24px rgba(0, 0, 0, 0.25);
  max-height: 500px;
  overflow-y: auto;
`;
