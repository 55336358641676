import { ClientResponse } from "./../api-types";
import theme from "../theme";

export function formatCpf(cpf: string) {
  if (!cpf) {
    return "";
  }
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function formatPhone(phone: string) {
  if (!phone) {
    return "";
  }
  return phone.replace(/(\d{5})(\d{4})/, "$1-$2");
}

export function onlyNumbers(string: string) {
  if (!string) {
    return "";
  }
  return string.replace(/\D+/g, "");
}

export function searchType(term: string) {
  if (isNaN((term as unknown) as number)) {
    // It is a string
    return `name=${term}`;
  } else {
    // It is a number
    return `document_or_whatsapp=${term}`;
  }
}

export const statusNameMap = {
  undefined: "Indefinido",
  inperson: "Em Pessoa",
  pending: "Pendente",
  disapproved: "Reprovado",
  approved: "Aprovado",
};

type ClientsFields<T> = {
  [P in keyof T]?: string;
};

export const clientFieldsMap: ClientsFields<ClientResponse> = {
  cpf: "CPF",
  whatsapp: "Whatsapp",
  telephone: "Telefones",
  proof_of_address: "Comprovante",
  dependent: "Dependente",
  recommendation: "Indicação",
  mother: "Nome da Mãe",
  information: "Informações",
};

export function getStatusColor(client: ClientResponse) {
  if (client.is_owing) {
    return theme.owing;
  } else if (client.is_registered) {
    return theme.status.client;
  } else {
    return theme.status[client.status];
  }
}
