import { IConfig } from "overmind";
import { createHook } from "overmind-react";

import { state } from "./state";
import { onInitialize } from "./onInitialize";
import * as actions from "./actions";
import * as effects from "./effects";

export const config = {
  onInitialize,
  state,
  actions,
  effects,
};

declare module "overmind" {
  interface Config extends IConfig<typeof config> {}
}

export const useOvermind = createHook<typeof config>();
