import React from "react";
import { useParams, useHistory } from "react-router-dom";

import ClientEdit from "../components/Client/ClientEdit";
import Header from "../components/Header/Header";
import PageContainer from "../components/Main/PageContainer";
import { useOvermind } from "../overmind";
import { ParamType } from "../api-types";

function ClientNew() {
  const history = useHistory();
  const { id } = useParams<ParamType>();
  const { state } = useOvermind();

  React.useEffect(() => {
    if (state.user.role === "employee") {
      history.push("/");
    }
  }, [history, state.user.role]);

  if (state.user.role === "employee") {
    return null;
  }

  return (
    <>
      <Header />

      <PageContainer>
        <ClientEdit key={id} newForm={true} />
      </PageContainer>
    </>
  );
}

export default ClientNew;
