import React from "react";

import Header from "../components/Header/Header";
import ClientItem from "../components/Client/ClientItem";
import PageContainer from "../components/Main/PageContainer";
import { useQueryClients } from "../utils/hooks";
import { useOvermind } from "../overmind";

function Home() {
  const { state } = useOvermind();
  const onSearch = useQueryClients();

  return (
    <>
      <Header onChange={onSearch} />
      <PageContainer>
        {state.clients.length > 0 &&
          state.clients.map((n) => <ClientItem key={n.id} data={n} />)}
      </PageContainer>
    </>
  );
}

export default Home;
