import React from "react";
import { Input, Form, Button, Alert } from "antd";
import { useHistory } from "react-router-dom";

import * as G from "./../components/global-styled";
import { useOvermind } from "../overmind";

function Login() {
  const [form] = Form.useForm();
  const history = useHistory();

  const { actions, state, reaction } = useOvermind();

  React.useEffect(() => {
    return reaction(
      (state) => state.user.id,
      () => history.push("/")
    );
  }, [history, reaction]);

  async function onSubmit(values: any) {
    actions.login(values);
  }

  return (
    <G.Login>
      <Form
        form={form}
        onFinish={onSubmit}
        layout="vertical"
        initialValues={{ username: "", password: "" }}
      >
        {state.loginError && (
          <Alert message={state.loginError} type="error" showIcon />
        )}
        <Form.Item name="username" label="Usuário" className="username">
          <Input placeholder="Digite seu nome de usuário" />
        </Form.Item>

        <Form.Item name="password" label="Senha" className="password">
          <Input type="password" placeholder="Digite sua senha" />
        </Form.Item>

        <Button type="primary" htmlType="submit" block={true}>
          Login
        </Button>
      </Form>
    </G.Login>
  );
}

export default Login;
