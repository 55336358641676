import { UserResponse, ClientResponse } from "./../api-types";

type State = {
  initialLoading: boolean;
  loginError: string | null;
  user: { id: number | null; role: string | null; token: string | null };
  clients: ClientResponse[];
  pending: ClientResponse[] | null;
  due: ClientResponse[] | null;
  currentClient: ClientResponse | null;
  loadingCurrentClient: boolean;
  users: UserResponse[];
};

export const state: State = {
  initialLoading: false,
  loginError: null,
  user: { id: null, role: null, token: null },
  clients: [],
  pending: null,
  due: null,
  currentClient: null,
  loadingCurrentClient: false,
  users: [],
};
