import React from "react";

import * as S from "./styled";

type Props = {
  color?: string;
  block?: boolean;
};

const Tag: React.FC<Props> = ({ children, color, block }) => {
  return (
    <S.Tag block={block} color={color}>
      {children}
    </S.Tag>
  );
};

export default Tag;
