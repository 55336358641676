import React from "react";
import { CloseCircleFilled } from "@ant-design/icons";
import { Popover } from "antd";

import theme from "../../theme";
import * as S from "./styled";
import { ClientResponse } from "../../api-types";
import Tag from "../Tag/Tag";
import { statusNameMap, formatCpf, getStatusColor } from "../../utils/utils";

const STYLES = {
  color: theme.error_color,
  fontSize: 18,
};

const ClientInputError: React.FC<{ client: ClientResponse }> = ({ client }) => {
  return (
    <Popover
      placement="left"
      content={
        <S.PopoverClient to={`/cliente/${client.id}`} target="_blank">
          <Tag color={getStatusColor(client)}>
            {client.is_owing ? "SPC" : statusNameMap[client.status]}
          </Tag>
          <div className="name">{client.name}</div>
          <div>{formatCpf(client.cpf)}</div>
        </S.PopoverClient>
      }
    >
      <S.InputIcon>
        <CloseCircleFilled style={STYLES} />
      </S.InputIcon>
    </Popover>
  );
};

export default ClientInputError;
