import decode from "jwt-decode";

import { ClientResponse } from "./../api-types";
import { theHistory } from "..";
import { Store } from "antd/lib/form/interface";
import { AsyncAction, Action } from "overmind";
import { network_temp } from "../utils/api";
import { notification } from "antd";
import { onlyNumbers } from "../utils/utils";

export const setCredentialsData: Action<string> = ({ state }, token) => {
  const { id, role } = decode(token);
  state.user.id = id;
  state.user.role = role;
  state.user.token = token;

  network_temp.interceptors.request.use((config) => {
    config.headers.Authorization = "Bearer " + state.user.token;

    return config;
  });
};

export const clearCredentials: Action = ({ state }) => {
  state.user.id = null;
  state.user.role = null;
  state.user.token = null;
};

export const clearCurrentClient: Action = ({ state }) => {
  state.currentClient = null;
};

export const clearUsers: Action = ({ state }) => {
  state.users = [];
};

export const clearState: Action = ({ state }) => {
  state.initialLoading = false;
  state.loginError = null;
  state.user = { id: null, role: null, token: null };
  state.clients = [];
  state.pending = null;
  state.due = null;
  state.currentClient = null;
  state.loadingCurrentClient = false;
  state.users = [];
};

export const login: AsyncAction<{
  username: string;
  password: string;
}> = async ({ state, actions, effects }, values) => {
  try {
    const res = await effects.api.login(values);
    if (res && res.data.token) {
      actions.setCredentialsData(res.data.token);
      state.loginError = null;
    }
  } catch (error) {
    state.loginError = error?.response?.data?.error ?? "There was an error.";
  }
};

export const getCurrentClient: AsyncAction<string> = async (
  { effects, state },
  id
) => {
  state.loadingCurrentClient = true;
  try {
    const res = await effects.api.getClient(id);
    if (res && res.data) {
      state.currentClient = res.data;
    } else {
      state.currentClient = null;
    }
  } catch (error) {
    if (error.response.status === 404) {
      theHistory.push("/");
    }
  }
  state.loadingCurrentClient = false;
};

export const getSearchedClients: AsyncAction<string> = async (
  { effects, state },
  searchTerm
) => {
  const res = await effects.api.getClients(searchTerm);
  if (res && res.data) {
    state.clients = res.data;
  } else {
    state.clients = [];
  }
};

export const getPendingClients: AsyncAction = async ({ effects, state }) => {
  if (!state.user.role || state.user.role === "employee") {
    return;
  }
  const res = await effects.api.getPendingClients();
  if (res && res.data) {
    state.pending = res.data;
  } else {
    state.pending = null;
  }
};

export const getDueClients: AsyncAction = async ({ effects, state }) => {
  if (!state.user.role || state.user.role === "employee") {
    return;
  }
  const res = await effects.api.getDueClients();
  if (res && res.data) {
    state.due = res.data;
  } else {
    state.due = null;
  }
};

export const clearClients: Action = ({ state }) => {
  state.clients = [];
};

export const logout: AsyncAction = async ({ effects, actions }) => {
  await effects.api.logout();
  actions.clearState();
};

export const registerClient: AsyncAction<number> = async (
  { effects, state },
  id
) => {
  state.currentClient!.is_registered = !state.currentClient!.is_registered;
  try {
    await effects.api.registerClient(id);
    state.currentClient!.client_code = "";
  } catch (error) {
    state.currentClient!.is_registered = !state.currentClient!.is_registered;
    notification.error({
      message: "Erro!",
      description: "Houve um erro, por favor, tente novamente.",
    });
  }
};

export const addClientCode: AsyncAction<{
  id: number;
  client_code: string;
}> = async ({ effects, state }, { id, client_code }) => {
  try {
    await effects.api.addClientCode(id, { client_code });
    state.currentClient!.client_code = client_code;
  } catch (error) {
    state.currentClient!.client_code = "";
    notification.error({
      message: "Erro!",
      description: "Houve um erro, por favor, tente novamente.",
    });
  }
};

export const editClient: AsyncAction<
  {
    id: string;
    values: Store;
  },
  ClientResponse | null
> = async ({ effects, actions, state }, client) => {
  client.values.cpf = onlyNumbers(client.values.cpf);
  client.values.whatsapp = onlyNumbers(client.values.whatsapp);

  try {
    const { data } = await effects.api.editClient(client.id, client.values);
    actions.getPendingClients();
    state.currentClient = data;
    notification.success({
      message: "Sucesso!",
      description: "Salvo com sucesso.",
    });
    return data;
  } catch (error) {
    notification.error({
      message: "Erro!",
      description: "Houve um erro, por favor, tente novamente.",
    });
    return null;
  }
};

export const postClient: AsyncAction<Store, number | null> = async (
  { effects },
  values
) => {
  values.cpf = onlyNumbers(values.cpf);
  values.whatsapp = onlyNumbers(values.whatsapp);

  try {
    const { data } = await effects.api.createClient(values);
    notification.success({
      message: "Sucesso!",
      description: "Criado com sucesso.",
    });
    return data.id;
  } catch (error) {
    notification.error({
      message: "Erro!",
      description: "Houve um erro, por favor, tente novamente.",
    });
    return null;
  }
};

export const removeClient: AsyncAction<string, boolean> = async (
  { effects, actions },
  id
) => {
  try {
    await effects.api.deleteClient(id);
    actions.getPendingClients();
    notification.success({
      message: "Sucesso!",
      description: "Removido com sucesso.",
    });
    return true;
  } catch (error) {
    notification.error({
      message: "Erro!",
      description: "Houve um erro, por favor, tente novamente.",
    });
    return false;
  }
};

export const getUsers: AsyncAction = async ({ effects, state }) => {
  try {
    const res = await effects.api.getUsers();
    if (res && res.data) {
      state.users = res.data;
    }
  } catch (error) {
    if (error.response.status === 404 || error.response.status === 403) {
      theHistory.push("/");
    }
  }
};

export const createUser: AsyncAction<{
  user: Store;
  callback: () => void;
}> = async ({ effects, state }, { user, callback }) => {
  try {
    const res = await effects.api.createUser(user);
    if (res && res.data) {
      state.users = [res.data, ...state.users];
      callback();
    }
  } catch (error) {
    notification.error({
      message: "Erro!",
      description: "Houve um erro, por favor, tente novamente.",
    });
  }
};

export const editUser: AsyncAction<{ id: number; values: Store }> = async (
  { effects, state },
  user
) => {
  if (!user.values.password) {
    delete user.values.password;
  }

  try {
    const res = await effects.api.editUser(user.id, user.values);
    if (res && res.data) {
      const index = state.users!.findIndex((d) => d.id === res.data.id);
      state.users![index] = res.data;

      notification.success({
        message: "Sucesso!",
        description: "Editado com sucesso.",
      });
    }
  } catch (error) {
    notification.error({
      message: "Erro!",
      description: "Houve um erro, por favor, tente novamente.",
    });
  }
};

export const addNetworkInterceptor: Action = ({ actions, state }) => {
  network_temp.interceptors.response.use(
    (response) => Promise.resolve(response),

    async (error) => {
      if (
        error.config.url === "/auth/login" ||
        error.config.url === "/auth/token"
      ) {
        return Promise.reject(error);
      }

      if (error.response.status === 401) {
        try {
          const res = await network_temp.post(
            "/auth/token",
            {},
            { withCredentials: true }
          );
          if (res && res.data.token) {
            const config = error.config;
            actions.setCredentialsData(res.data.token);
            return network_temp
              .request(config)
              .then((data) => Promise.resolve(data))
              .catch((requestError) => Promise.reject(requestError));
          }
          actions.logout();
          return Promise.reject(error);
        } catch (retryError) {
          actions.logout();
          return Promise.reject(error);
        }
      }
      return Promise.reject(error);
    }
  );
};
