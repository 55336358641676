import React from "react";

import * as S from "./styled";

const UserNew: React.FC<{ onClick: (e: any) => void }> = ({
  children,
  onClick,
}) => {
  return <S.Button onClick={onClick}>{children}</S.Button>;
};

export default UserNew;
