import { OnInitialize } from "overmind";

export const onInitialize: OnInitialize = async ({
  actions,
  effects,
  state,
}) => {
  state.initialLoading = true;

  try {
    const res = await effects.api.getToken();
    if (res && res.data.token) {
      actions.setCredentialsData(res.data.token);
    }
    state.initialLoading = false;
  } catch (error) {
    state.initialLoading = false;
  }

  actions.addNetworkInterceptor();
};
