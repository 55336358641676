import React from "react";

import { UserResponse } from "../../api-types";
import * as S from "./styled";
import { Tag } from "antd";
import theme from "../../theme";

const UserItem: React.FC<{
  data: UserResponse;
  onClick: (id: number) => void;
}> = ({ data, onClick }) => {
  function onOpen() {
    onClick(data.id);
  }

  return (
    <S.Item onClick={onOpen}>
      <div>
        <S.Tag>
          <Tag>{data.role}</Tag>
        </S.Tag>

        <S.Name>
          {data.first_name} {data.last_name}
        </S.Name>
      </div>

      <div>
        {data.is_active ? (
          <S.Status>
            <Tag color={theme.status.approved}>Ativo</Tag>
          </S.Status>
        ) : (
          <S.Status>
            <Tag color={theme.status.disapproved}>Inativo</Tag>
          </S.Status>
        )}
      </div>
    </S.Item>
  );
};

export default UserItem;
