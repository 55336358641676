import React from "react";

import { useOvermind } from "./../overmind/index";
import { searchType } from "./utils";

export const useQueryClients = (): ((
  e: React.ChangeEvent<HTMLInputElement>
) => void) => {
  const { actions } = useOvermind();
  const [searchTerm, setSearchTerm] = React.useState("");

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchTerm.trim() && searchTerm.trim().length >= 3) {
        actions.getSearchedClients(searchType(searchTerm));
      }
    }, 300);

    if (!searchTerm.trim()) {
      actions.clearClients();
    }

    return () => clearTimeout(timeout);
  }, [searchTerm, actions]);

  function onSearch(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setSearchTerm(value);
  }

  return onSearch;
};
